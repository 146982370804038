@import url("https://fonts.googleapis.com/css2?family=Coiny&display=swap");

@font-face {
  font-family: "Impact";
  src: url(assets/fonts/Impact.ttf);
}

// Reset css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Coiny", sans-serif;
  font-size: 16px;
  background: #000;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}

// App
.App {
  .container {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    &.wrapper {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 24px;
    }
  }
  .btn {
    width: 220px;
    padding: 21px 0;
    font-size: 24px;
    font-weight: 400;
    line-height: 26.4px;
    text-align: center;
    color: #fff;
    border: 2px solid #000;
    box-shadow: 8px 8px 0px 0px #000;
    border-radius: 24px;
    overflow: hidden;
    &:hover {
      opacity: 0.8;
    }
  }
  .page-main {
    .section-intro {
      max-height: 1080px;
      height: 100vh;
      background-image: url(assets/images/img-bg-intro.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 100px;
      .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 48px;
        height: 100%;
        .value {
          width: 796px;
          padding: 14px 0;
          border: 2px solid #000;
          background: #fff;
          font-size: 36px;
          font-weight: 400;
          line-height: 39.6px;
          border-radius: 34px;
          text-align: center;
          @media only screen and (max-width: 848px) {
            width: 600px;
            font-size: 28px;
            line-height: 28px;
          }
          @media only screen and (max-width: 654px) {
            width: 400px;
            font-size: 18px;
            line-height: 24px;
          }
          @media only screen and (max-width: 480px) {
            width: 320px;
            font-size: 14px;
            line-height: 18px;
          }
        }
        .btn-groups {
          display: flex;
          align-items: center;
          gap: 24px;
          padding-bottom: 15px;
          @media only screen and (max-width: 480px) {
            flex-direction: column;
          }
          .btn {
            box-sizing: border-box;
            @media only screen and (max-width: 496px) {
              padding: 14px 0;
              font-size: 18px;
            }
          }
          .btn-buy {
            border: 2px solid #000;
            background: #df1830;
          }
          .btn-chart {
            border: 2px solid #fff;
            background: #000;
          }
        }
      }
    }
    .section-about {
      background-image: url(assets/images/img-bg-about.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 144px 24px;
      @media only screen and (max-width: 668px) {
        padding: 60px 24px;
      }
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 48px;
        @media only screen and (max-width: 896px) {
          flex-direction: column;
        }
        @media only screen and (max-width: 668px) {
          gap: 24px;
        }
        .img-left {
          width: 576px;
          @media only screen and (max-width: 668px) {
            width: auto;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          gap: 24px;
          @media only screen and (max-width: 896px) {
            align-items: center;
            text-align: center;
          }
          @media only screen and (max-width: 668px) {
            gap: 12px;
          }
          @media only screen and (max-width: 480px) {
            gap: 8px;
            min-height: 500px;
          }
          .title {
            font-family: "Impact";
            font-size: 64px;
            font-weight: 400;
            line-height: 78.06px;
            color: #fff;
            text-shadow: 2px 2px 0 #000, -2px 2px 0 #000, -2px -2px 0 #000,
              2px -2px 0 #000;
            span {
              color: #df1830;
            }
            @media only screen and (max-width: 668px) {
              font-size: 48px;
            }
            @media only screen and (max-width: 524px) {
              font-size: 32px;
              line-height: 48px;
            }
            @media only screen and (max-width: 480px) {
              font-size: 28px;
            }
          }
          .paragraph {
            max-width: 576px;
            font-size: 24px;
            font-weight: 400;
            line-height: 26.4px;
            text-transform: uppercase;
            @media only screen and (max-width: 668px) {
              font-size: 18px;
            }
            @media only screen and (max-width: 480px) {
              font-size: 14px;
            }
          }
        }
      }
    }
    .section-image {
      .container {
        display: flex;
        align-items: center;
        .img-dog {
          width: calc(100% / 4);
          img {
            width: 100%;
            height: 480px;
            object-fit: cover;
            @media only screen and (max-width: 1680px) {
              height: 380px;
            }
            @media only screen and (max-width: 1396px) {
              height: 300px;
            }
            @media only screen and (max-width: 1180px) {
              height: 250px;
            }
            @media only screen and (max-width: 896px) {
              height: 200px;
            }
            @media only screen and (max-width: 668px) {
              height: 160px;
            }
            @media only screen and (max-width: 480px) {
              height: 100px;
            }
          }
        }
      }
    }
    .section-tokenomics {
      background-image: url(assets/images/img-bg-token.png);
      background-repeat: no-repeat;
      background-size: cover;
      .container {
        padding: 180px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 220px;
        @media only screen and (max-width: 1080px) {
          padding: 60px 24px;
          padding-bottom: 100px;
          gap: 100px;
        }
        @media only screen and (max-width: 668px) {
          gap: 50px;
        }
        .content {
          &-1 {
            .title-content {
              font-family: "Impact";
              font-size: 64px;
              font-weight: 400;
              line-height: 78.06px;
              color: #fff;
              text-align: center;
              text-shadow: 2px 2px 0 #000, -2px 2px 0 #000, -2px -2px 0 #000,
                2px -2px 0 #000;
            }
            .list-token {
              display: flex;
              align-items: center;
              gap: 24px;
              margin-top: 48px;
              @media only screen and (max-width: 1080px) {
                flex-wrap: wrap;
                justify-content: center;
              }
              .item {
                border: 2px solid #000;
                box-shadow: 8px 8px 0px 0px #000;
                background: #fbfdfd;
                border-radius: 24px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 26px 0 42px;
                @media only screen and (max-width: 480px) {
                  padding: 18px 0 24px;
                }
                .img-token {
                  img {
                    height: 276px;
                  }
                }
                .content {
                  margin-top: 31px;
                  text-align: center;
                  .title {
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 26.4px;
                    color: #df1830;
                    margin-bottom: 24px;
                    @media only screen and (max-width: 480px) {
                      margin-bottom: 16px;
                    }
                  }
                  .value {
                    font-size: 48px;
                    font-weight: 400;
                    line-height: 52.8px;
                    text-align: center;
                  }
                }
              }
            }
          }
          &-2 {
            display: flex;
            gap: 48px;
            @media only screen and (max-width: 968px) {
              flex-direction: column;
              align-items: center;
            }
            .left {
              display: flex;
              flex-direction: column;
              gap: 24px;
              @media only screen and (max-width: 968px) {
                align-items: center;
              }
              .title {
                font-family: "Impact";
                font-size: 64px;
                font-weight: 400;
                line-height: 78.06px;
                color: #fff;
                text-shadow: 2px 2px 0 #000, -2px 2px 0 #000, -2px -2px 0 #000,
                  2px -2px 0 #000;
                span {
                  color: #df1830;
                }
                @media only screen and (max-width: 1180px) {
                  font-size: 56px;
                }
                @media only screen and (max-width: 1080px) {
                  font-size: 48px;
                }
                @media only screen and (max-width: 968px) {
                  text-align: center;
                }
                @media only screen and (max-width: 480px) {
                  font-size: 36px;
                }
              }
              .list {
                display: flex;
                flex-direction: column;
                gap: 24px;
                @media only screen and (max-width: 968px) {
                  text-align: center;
                }
                li {
                  .h2b {
                    color: #df1830;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 26.4px;
                    text-transform: uppercase;
                    @media only screen and (max-width: 480px) {
                      font-size: 18px;
                    }
                  }
                  border-radius: 24px;
                  max-width: 576px;
                  border: 2px solid #000;
                  box-shadow: 8px 8px 0px 0px #000;
                  background: #fbfdfd;
                  padding: 24px;
                  box-sizing: border-box;
                  .des {
                    margin-top: 12px;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 22px;
                    padding-left: 26px;
                    text-transform: uppercase;
                    @media only screen and (max-width: 480px) {
                      font-size: 16px;
                      line-height: 18px;
                    }
                    span {
                      color: #00b2ff;
                    }
                  }
                }
              }
            }
            .right {
              border: 2px solid #000;
              box-shadow: 8px 8px 0px 0px #000;
              border-radius: 24px;
              overflow: hidden;
              width: 576px;
              height: 626px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              @media only screen and (max-width: 644px) {
                width: 476px;
                height: 526px;
              }
              @media only screen and (max-width: 540px) {
                width: 376px;
                height: 426px;
              }
              @media only screen and (max-width: 480px) {
                width: 300px;
                height: 326px;
              }
            }
          }
          &-3 {
            position: relative;
            p {
              font-family: "Impact";
              font-size: 64px;
              font-weight: 400;
              line-height: 78.06px;
              text-transform: uppercase;
              color: #fff;
              text-shadow: 2px 2px 0 #000, -2px 2px 0 #000, -2px -2px 0 #000,
                2px -2px 0 #000;
              position: absolute;
              transform: translate(-50%, -50%);
              top: 50%;
              left: 50%;
              width: 100%;
              text-align: center;
              @media only screen and (max-width: 624px) {
                font-size: 48px;
              }
              @media only screen and (max-width: 480px) {
                font-size: 36px;
              }
            }
          }
        }
      }
    }
  }
  .page-footer {
    background: #9ed4ff;
    position: relative;
    .img-left {
      position: absolute;
      width: 282px;
      top: -72px;
      left: 0;
      @media only screen and (max-width: 676px) {
        width: 180px;
        top: 46px;
      }
    }
    .img-right {
      width: 283px;
      height: 252px;
      position: absolute;
      right: 0;
      top: 5px;
      @media only screen and (max-width: 676px) {
        width: 180px;
        top: 96px;
      }
    }
    @media only screen and (max-width: 546px) {
      .img-left {
        top: 154px;
      }
      .img-right {
        top: 186px;
      }
      .img-left,
      .img-right {
        width: 120px;
      }
    }
    .container {
      .content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 42px;
        position: relative;
        .main {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          .img-title {
            width: 622px;
            @media only screen and (max-width: 676px) {
              width: auto;
            }
          }
          .btn-groups {
            display: flex;
            align-items: center;
            gap: 24px;
            @media only screen and (max-width: 546px) {
              flex-direction: column;
              .btn {
                padding: 12px 0;
                font-size: 18px;
              }
            }
            .btn-tele {
              background: #00b2ff;
            }
            .btn-x {
              background: #000;
              border: 2px solid #ffffff;
            }
          }
        }
      }
    }
    .copyright {
      // position: relative;
      background-image: url(assets/images/img-copyright.png);
      background-repeat: no-repeat;
      background-size: cover;
      height: 134px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        color: #fff;
        padding-bottom: 23px;
        text-transform: uppercase;
        @media only screen and (max-width: 676px) {
          font-size: 14px;
        }
      }
    }
  }
}

.btn-groups-h {
  display: flex;
  align-items: center;
  gap: 24px;
  @media only screen and (max-width: 546px) {
    flex-direction: column;
    .btn {
      padding: 12px 0;
      font-size: 18px;
    }
  }
  .btn-tele {
    background: #00b2ff;
  }
  .btn-x {
    background: #000;
    border: 2px solid #ffffff;
  }
}
